import { BigNumber, ethers } from "ethers";
import apiRequest from "../../helpers/connections.js";

import { notificationActions } from "../../store/notification/notification.js";
import {
    MARKETPLACE,
    MARKETPLACEABI,
    NFTABI,
    NFTCONTRACT,
    REWARDTOKEN,
    STAKEABI,
    STAKECONTRACT,
} from "../../config.js";
import axios from "axios";

export function numberToTwoDecimals(num) {
    if (num) {
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        return Number(with2Decimals);
    } else {
        return 0;
    }
}

export const stakeHelper = async(dispatch, id, navigate, address) => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(STAKECONTRACT, STAKEABI, signer);

        try {
            const response = await contract.stake(NFTCONTRACT, id, REWARDTOKEN);
            await response.wait();
            const stakeIds = await contract.stakeIds(address);

            navigate("/stake/staked/" + stakeIds[stakeIds.length - 1]);
            dispatch(
                notificationActions.setMessage("Your NFT staking was succesful")
            );
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const checkApproved = async(address, setApproved, dispatch) => {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);

        try {
            //debugger;
            const response = await contract.isApprovedForAll(address, STAKECONTRACT);
            response ? setApproved(true) : setApproved(false);
        } catch (error) {
            console.log("error", error);
        }
    }
};

export const handleStakingApproval = async(dispatch, setApproved) => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);

        try {
            //debugger;
            const response = await contract.setApprovalForAll(STAKECONTRACT, true);
            await response.wait();

            setApproved(true);

            dispatch(notificationActions.setMessage("Approval Completed"));
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const handleUnStake = async(id, dispatch, navigate) => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(STAKECONTRACT, STAKEABI, signer);

        try {
            //debugger;
            const response = await contract.unstake(id);

            await response.wait();

            dispatch(notificationActions.setMessage("UnStake Successful"));
            navigate("/stake");
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const rewardHistory = async(address, setRewardHistory) => {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(STAKECONTRACT, STAKEABI, signer);
        try {
            //debugger;
            const response = await contract.rewardHistory(address);
            const num = BigNumber.from(`${response._rewardsPaid[0]._hex}`).toString();
            const tokens = Number(num) * 10 ** -18;

            setRewardHistory(tokens);
        } catch (error) {
            console.log("error", error);
        }
    }
};

export const handleClaim = async(
    address,
    dispatch,
    setClaimBal,
    setRewardHistory
) => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(STAKECONTRACT, STAKEABI, signer);

        try {
            //debugger;
            const response = await contract.claimAll(address);
            await response.wait();
            dispatch(notificationActions.setMessage("Rewards Claimed"));
            setClaimBal(0);
            await rewardHistory(address, setRewardHistory);
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const pendingClaim = async(address, setClaimBal) => {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(STAKECONTRACT, STAKEABI, signer);
        try {
            //debugger;
            let allTokens = 0;
            const response = await contract.claimable(address);
            response.forEach((claim) => {
                const num = BigNumber.from(`${claim.amount._hex}`).toString();
                const tokens = Number(num) * 10 ** -18;
                allTokens += tokens;
            });
            setClaimBal(allTokens);
        } catch (error) {
            console.log("error", error);
        }
    }
};

export const numberStaked = async(address, setStakedAmount) => {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(STAKECONTRACT, STAKEABI, signer);
        try {
            //debugger;
            const response = await contract.stakeIds(address);
            setStakedAmount(response.length);
        } catch (error) {
            console.log("error", error);
        }
    }
};

export const getStakedIds = async(dispatch, address) => {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(STAKECONTRACT, STAKEABI, signer);

        try {
            const stakeIds = await contract.stakeIds(address);
            return stakeIds;
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const getTokenIds = async(dispatch, stakedIds) => {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(STAKECONTRACT, STAKEABI, signer);

        try {
            const valueFee = {
                value: ethers.utils.parseEther(`${0}`),
                gasLimit: 3000000,
            };
            const tokenIds = await new Promise((resolve, reject) => {
                const _tokenids = [];
                stakedIds.map(async(stakeid, index) => {
                    const tokenid = await contract.stakedtokenId(stakeid, valueFee);
                    _tokenids.push({
                        stakeid,
                        tokenId: Number(BigNumber.from(`${tokenid._hex}`).toString()),
                    });

                    if (index === stakedIds.length - 1) {
                        resolve(_tokenids);
                    }
                });
            });
            return tokenIds;
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const getMetaInfo = async(dispatch, ids) => {
    const metadata = await new Promise((resolve, reject) => {
        const base = "https://pangs-collection.s3.amazonaws.com/meta/";
        const meta = [];
        return ids.map(async(id, index) => {
            try {
                const res = await axios.get(base + id.tokenId + ".json");
                meta.push({ stakeId: id.stakeid, data: res.data });

                if (index === ids.length - 1) {
                    resolve(meta);
                }
            } catch (error) {
                dispatch(notificationActions.setMessage(error.message));
                resolve([]);
            }
        });
    });

    return metadata;
};

export const getMeta = async(dispatch, id) => {
    const metadata = await new Promise(async(resolve, reject) => {
        const base = "https://pangs-collection.s3.amazonaws.com/meta/";
        try {
            const res = await axios.get(base + id + ".json");
            const response = await apiRequest(
                "orders/floor",
                undefined,
                undefined,
                undefined
            );
            resolve({...res.data, ...response.data });
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
            resolve([]);
        }
    });

    return metadata;
};

export const getTokenId = async(dispatch, stakedId) => {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(STAKECONTRACT, STAKEABI, signer);

        try {
            const tokenid = await contract.stakedtokenId(stakedId);
            return Number(BigNumber.from(`${tokenid._hex}`).toString());
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const getPangStats = async(dispatch) => {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);

        try {
            const res = await contract.totalSupply();
            const totalSupply = Number(BigNumber.from(`${res._hex}`).toString());
            console.log(totalSupply);
            const tokenHolders = await new Promise(async(resolve, reject) => {
                const _tokenHolders = [];
                for (let i = 1; i <= totalSupply; i++) {
                    const holder = await contract.ownerOf(i);
                    !_tokenHolders.includes(holder) && _tokenHolders.push(holder);
                    if (i === totalSupply) {
                        resolve(_tokenHolders);
                    }
                }
            });

            return { supply: totalSupply, holders: tokenHolders.length };
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const collectionStats = async() => {
    try {
        const response = await apiRequest(
            "transactions/collectionstats",
            undefined,
            undefined,
            undefined
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const orders = async() => {
    try {
        const response = await apiRequest(
            "orders",
            undefined,
            undefined,
            undefined
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const orderByAddress = async(address) => {
    try {
        const response = await apiRequest(
            "orders/address/" + address,
            undefined,
            undefined,
            undefined
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const transactions = async() => {
    try {
        const response = await apiRequest(
            "transactions",
            undefined,
            undefined,
            undefined
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const userTransactions = async(address) => {
    try {
        const response = await apiRequest(
            "transactions/user/" + address,
            undefined,
            undefined,
            undefined
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const tokenTransactions = async(id) => {
    try {
        const response = await apiRequest(
            "transactions/token/" + id,
            undefined,
            undefined,
            undefined
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};
export const checkSaleApproved = async(address, setApproved, dispatch) => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);

        try {
            //debugger;
            const response = await contract.isApprovedForAll(address, MARKETPLACE);
            response ? setApproved(true) : setApproved(false);
            dispatch(notificationActions.setNotify(false));
        } catch (error) {
            console.log("error", error);
        }
    }
};

export const handleSaleApproval = async(dispatch, setApproved) => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);

        try {
            //debugger;
            const response = await contract.setApprovalForAll(MARKETPLACE, true);
            await response.wait();

            setApproved(true);

            dispatch(notificationActions.setMessage("Approval Completed"));
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const listToken = async(dispatch, id, price, navigate) => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(MARKETPLACE, MARKETPLACEABI, signer);

        try {
            const response = await contract.makeItem(
                NFTCONTRACT,
                id,
                ethers.utils.parseEther(`${price}`)
            );
            await response.wait();

            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true)
                }, 3000);
            })

            dispatch(
                notificationActions.setMessage("Your NFT listing was succesful")
            );
            navigate("/pangs-collection/listing/" + id);


        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const unListToken = async(dispatch, id, navigate) => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(MARKETPLACE, MARKETPLACEABI, signer);

        try {
            const response = await contract.unListItem(id);
            await response.wait();

            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true)
                }, 3000);
            })

            dispatch(
                notificationActions.setMessage("Your NFT listing has been removed")
            );
            navigate("/account");


        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const purchase = async(dispatch, id, fee, navigate) => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(MARKETPLACE, MARKETPLACEABI, signer);
        const valueFee = {
            value: ethers.utils.parseEther(`${fee + (0.05 * fee)}`),
            gasLimit: 1400000,
        };
        try {
            const response = await contract.purchaseItem(id, valueFee);
            await response.wait();

            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true)
                }, 3000);
            })

            dispatch(
                notificationActions.setMessage("Your NFT listing was succesful")
            );
            navigate("/account");


        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

export const orderByTokenId = async(id) => {
    try {
        const response = await apiRequest(
            "orders/id/" + id,
            undefined,
            undefined,
            undefined
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};