import React from "react";

import styles from "./text.module.css";
import Button from "../../button/button";
import { useSelector } from "react-redux";

const Text = () => {
  const { totalStaked } = useSelector((state) => state.herostats);

  return (
    <div className={styles.text}>
      <h1>
        The EXCLUSIVE Marketplace for Purchasing, Staking & Trading the{" "}
        <span> Wu Pangs NFT Collection on Ethereum</span>
      </h1>

      <p>
        10,000 individual NFTs consisting of 61 Traits varying in rarity. "Check
        out our “Perks” section for a detailed breakdown of the vast Utilities"
        and Rewards that come with being an owner
      </p>

      <Button path="/pangs-collection" title="View Collection" />
      <div className={styles.stats}>
        <div>
          <p>61</p>
          <span>Unique NFTs</span>
        </div>
        <div></div>
        
        <div>
          <p>{totalStaked}</p>
          <span>Staked NFTs</span>
        </div>
        <img
          className={styles.pangsgif}
          src={require("../../../../assets/pangsgif.gif")}
          alt="pangsgif"
        />
      </div>
    </div>
  );
};

export default Text;
