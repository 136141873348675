import React from "react";
import styles from "./perks.module.css";

const Perks = () => {
  return (
    <div className={styles.perks}>
      <h2>PERKS & REWARDS</h2>
      <p>
        Owning a Wu Pangs (WP) NFT will give you access to a MASSIVE
        VARIETY of both IRL and online Utilities. As a WP owner, you will be part of a community that
        is focused on awarding tangible rewards, benefits, and utilities to its
        owners. This starts with owners receiving Merch with their NFT on it
      </p>
      <div>
        <div>
          <h3>Merchandize</h3>
          <p>
            Every owner will receive at least one piece of Apparel with the
            purchase of their NFT. Different rarity levels have different reward
            levels of merch (see below for levels and merch). As well as
            Stickers, Playing Cards, Poker Chips, and much more to coming soon
          </p>
        </div>

        <div>
          <h3>Casino</h3>
          <p>
            Owners also receive a multitude of rewards on our online Casino;
            Pangs Casino Club (link on Home Page). With Loot Crate Staking available on the
            Casino and bonuses on Deposits. Owners can pair their NFT with their
            membership in the Pangs Casino Club for HUGE rewards (see below for
            bonus percentages)
          </p>
        </div>
      </div>
    </div>
  );
};

export default Perks;
