import React from "react";
import { animateScroll } from "react-scroll";

import styles from "./footer.module.css";
import { Link } from "react-router-dom";
const Footer = () => {
  const surfacePush = () => {
    animateScroll.scrollToTop();
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.platform}>
        <h4>Wu Pangs</h4>
        <div className={styles.socials}>
          <a href="#f">
            <img src={require("../../assets/discord.png")} alt="discord" />
          </a>
          <a href="#f">
            <img src={require("../../assets/twitter.png")} alt="twitter" />
          </a>
          <a href="#f">
            <img src={require("../../assets/telegram.png")} alt="telegram" />
          </a>
        </div>
      </div>
      <div className={styles.links}>
        <div>
          <Link to="/account">Dashboard</Link>{" "}
          <img src={require("../../assets/arr.png")} alt="arr" />
        </div>
        <div>
          <Link to="/mint">Mint</Link>{" "}
          <img src={require("../../assets/arr.png")} alt="arr" />
        </div>
        <div>
          <Link to="/stake">Stake</Link>{" "}
          <img src={require("../../assets/arr.png")} alt="arr" />
        </div>
        <div>
          <Link to="/pangs-collection">Pangs Collection</Link>{" "}
          <img src={require("../../assets/arr.png")} alt="arr" />
        </div>
      </div>
      <div onClick={surfacePush}>
        <p>Scroll to Top</p>
        <img src={require("../../assets/top.png")} alt="to top" />
      </div>
    </footer>
  );
};

export default Footer;
