import React, { useEffect, useState } from "react";
import styles from "./header.module.css";
import ConnectButton from "../connectButton/connectButton";
import { useSelector } from "react-redux";
import { NavLink,  useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const { connected } = useSelector(
    (state) => state.connection.connectionState
  );
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 400);
  }, [connected, pathname]);
  return (
    <header className={styles.header}>
      <div  className={styles.logoflex} onClick={()=> navigate("/")}>
        <img src={require("../../assets/logo.png")} alt="logo" />
      </div>
      <img
        className={open ? styles.hide : undefined}
        onClick={() => setOpen(true)}
        src={require(`../../assets/ham.png`)}
        alt="toggle"
      />
      <div className={`${styles.menu} ${open ? styles.show : undefined}`}>
        <div className={styles.mobheader}>
          <h2>Wu Pangs</h2>
          <img
            onClick={() => setOpen(false)}
            src={require(`../../assets/cancel.png`)}
            alt="toggle"
          />
        </div>
        <ul>
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            to="/mint"
          >
            Mint
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            to="/pangs-collection"
          >
            Pangs Collection
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            to="/catalogue"
          >
            Catalogue
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            to="/perks"
          >
            Perks
          </NavLink>
          {connected && (
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              to="/stake"
            >
              Stake
            </NavLink>
          )}
        </ul>
        {pathname === "/mint" && !connected ? <></> : <ConnectButton />}
      </div>
    </header>
  );
};

export default Header;
