import React, { useRef, useState } from "react";
import { ethers } from "ethers";
import { useSelector, useDispatch } from "react-redux";
import Web3Modal from "web3modal";
import useOutsideAlerter from "../../hooks/useOutsideClick";

import { connectionActions } from "../../store/connection/connection";
import { providerOptions } from "./providerOptions";

import styles from "./connectButton.module.css";
import { useNavigate } from "react-router";
const ConnectButton = ({ className }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const controllerRef = useRef(null);
  const { connected, address } = useSelector(
    (state) => state.connection.connectionState
  );

  const navigate = useNavigate();

  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    providerOptions,
    cacheProvider: false, // required
  });

  const makeConnection = async () => {
    const provider = await web3Modal.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();

    //set connection state

    dispatch(
      connectionActions.setConnection({
        connected: true,
        address: accounts[0],
      })
    );
  };

  const connectButtonHandler = async () => {
    if (connected && !!address) {
      ///disconnect
      setOpen(false);
      web3Modal.clearCachedProvider();
      dispatch(
        connectionActions.setConnection({ connected: false, address: "" })
      );
    } else {
      //connect
      await makeConnection();
    }
  };

  const beforeConnect = (
    <div className={`${styles.buttonContainer} ${className}`}>
      <button onClick={connectButtonHandler}>Connect Wallet</button>
    </div>
  );

  useOutsideAlerter(ref, controllerRef, () => setOpen(false));

  const afterConnect = (
    <div className={styles.container}>
      <div
        ref={controllerRef}
        onClick={() => setOpen((prevstate) => !prevstate)}
        className={styles.connected}
      >
        <img src={require("../../assets/avatar.png")} alt="profile" />
        <img src={require("../../assets/ArrowDown.png")} alt="dropdown" />
      </div>

      <div ref={ref} className={open ? styles.open : undefined}>
        <li
          onClick={() => {
            navigate("/account");
            setOpen(false);
          }}
          className={styles.grid}
        >
          <img src={require("../../assets/va.png")} alt="my profile" />
          <p>My Profile</p>
        </li>
        <li className={styles.grid} onClick={connectButtonHandler}>
          <img src={require("../../assets/logout.png")} alt="logout" />
          <p>Disconnect</p>
        </li>
      </div>
    </div>
  );

  return <>{!connected ? beforeConnect : afterConnect}</>;
};

export default ConnectButton;
