import React, { useState } from "react";

import styles from "./mintBox.module.css";
import { useDispatch, useSelector } from "react-redux";
import ConnectButton from "../../connectButton/connectButton";
import { BigNumber, ethers } from "ethers";
import { NFTABI, NFTCONTRACT } from "../../../config";
import { notificationActions } from "../../../store/notification/notification";
import axios from "axios";
import apiRequest from "../../../helpers/connections";

const MintBox = ({ setshowPop, setnewNFT }) => {
  const { connected, address } = useSelector(
    (state) => state.connection.connectionState
  );

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const fee = 0.25;

  const mintHandler = async () => {
    if (window.ethereum && email) {
      dispatch(notificationActions.setNotify(true));
      let data;

      try {
        const res = await apiRequest(
          "whitelist/check/"+address,
          undefined,
          undefined,
          undefined
        );
        data = res.signature;
      } catch (error) {
        dispatch(notificationActions.setMessage(error.message));
        
      }

      
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);

      try {
        //get mint fee
        const wlFeeRes = await contract.whitelistFee();
        const mintFeeRes = await contract.fee();
        const wlFee = Number(BigNumber.from(`${wlFeeRes._hex}`).toString()) / 10**18
        const mintFee = Number(BigNumber.from(`${mintFeeRes._hex}`).toString()) / 10**18

        let valueFee;

        if (data === "0x17155536b112b3fdeba2a37a8c24f47ff3795338cb22c1cb71ad76ff55946c155ffba9cb25225bea8d7c067964170004d38f1e1993633b58c39e03bfeb952d2a1c") {
           valueFee = {
            value: ethers.utils.parseEther(`${mintFee * amount}`),
            gasLimit: 4800000 *amount,
          };
        } else if(data !== "0x17155536b112b3fdeba2a37a8c24f47ff3795338cb22c1cb71ad76ff55946c155ffba9cb25225bea8d7c067964170004d38f1e1993633b58c39e03bfeb952d2a1c" && amount === 1){
          valueFee = {
            value: ethers.utils.parseEther(`${wlFee * amount}`),
            gasLimit: 4800000 *amount,
          };
        }else{
          valueFee = {
            value: ethers.utils.parseEther(`${(mintFee * (amount-1)) + wlFee}`),
            gasLimit: 4800000 *amount,
          };
        }



        const response = await contract.mintNFT(amount, data, valueFee);
        await response.wait();

        const owned = await contract.Owned(address);

        const uri = await contract.tokenURI(owned[owned.length - 1]);

        const json = await axios({ method: "get", url: uri });

        dispatch(notificationActions.setNotify(false));

        await apiRequest("nfts/add/"+email, undefined, undefined, undefined);

        setnewNFT({ image: json.data.image, name: json.data.name });
        setshowPop(true);
      } catch (error) {
        dispatch(notificationActions.setMessage(error.message));
      }
    }
  };

  const [amount, setAmount] = useState(1);
  const beforeConnect = (
    <>
      <ConnectButton className={styles.large} />
    </>
  );
  const afterConnect = (
    <>
      <input
        className={styles.email}
        type="text"
        value={email}
        placeholder="example@email.com"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <div className={styles.input}>
        <p
          onClick={() => amount > 1 && setAmount((prevstate) => prevstate - 1)}
        >
          -
        </p>
        <input
          onChange={(e) => setAmount(e.target.value)}
          value={amount}
          type="tel"
          name="amount"
        />
        <p onClick={() => setAmount((prevstate) => prevstate + 1)}>+</p>
      </div>
      <div className={styles.tmint}>
        <span>Total Mint</span>
        <div>
          <p>{amount * fee}</p>
          <img src={require("../../../assets/eth.png")} alt="eth" />
        </div>
      </div>
      <button onClick={mintHandler} className={styles.mintButton}>
        Mint
      </button>
    </>
  );
  return (
    <div className={styles.mint}>
      <h2>Wu Pangs NFT Mint</h2>
      <p>
        Upon Purchase your Wu Pangs NFT will draw at random from 10,000
        individual NFTs, consisting of 61 unique traits, each of which has
        different rarity levels and point values. Different levels and point
        values award you different levels of both IRL and Casino Perks. Check
        the “catalogue” after your purchase for the back story behind the Wu
        Pang Clan, your Pangs personal Bio, and its rarity. Good Luck
      </p>

      <div className={styles.stats}>
        <div>
          <span>Mint Price</span>
          <div>
            <p>0.125</p>
            <img src={require("../../../assets/eth.png")} alt="eth" />
          </div>
        </div>
        <div>
          <span>Total Supply</span>
          <div>
            <p>10k</p>
          </div>
        </div>
      </div>

      {connected ? afterConnect : beforeConnect}
    </div>
  );
};

export default MintBox;
