import React from "react";
import styles from "./whitelist.module.css";
import { useState } from "react";
import apiRequest from "../../helpers/connections";
import { useDispatch, useSelector } from "react-redux";
import { notificationActions } from "../../store/notification/notification";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { ethers } from "ethers";
import { REWARDTOKEN, REWARDTOKENABI, STAKECONTRACT } from "../../config";

const Whitelist = () => {
  const { message } = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [logged, setLogged] = useState(false);
  const [emails, setEmails] = useState(0)

    

  const [address, setAddress] = useState("");
  const addAddress = async () => {
    if (address.length === 0) {
      return;
    }
    dispatch(notificationActions.setNotify(true));

    try {
      await apiRequest(
        "whitelist/add/" + address,
        undefined,
        undefined,
        undefined
      );
      dispatch(notificationActions.setMessage("Address has been whitelisted"));
      setAddress("")
    } catch (error) {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  };

  const removeAddress = async (addy) => {
    dispatch(notificationActions.setNotify(true));

    try {
      await apiRequest(
        "whitelist/remove/" + addy,
        undefined,
        undefined,
        undefined
      );
      dispatch(notificationActions.setMessage("Address has been removed"));
    } catch (error) {
      dispatch(notificationActions.setMessage("Something went wrong"));
    }
  };

  const handlePullCSV = async (e) => {
    dispatch(notificationActions.setNotify(true));

    const response = await axios({
      method: "get",
      url: "https://pangs-backend.onrender.com/nfts/csv",
      responseType: "blob",
    });

    const blob = response.data;

    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `WuPangs Emails.csv`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    dispatch(notificationActions.setNotify(false));

    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const handleRewardApproval = async() => {
    if (window.ethereum) {
        dispatch(notificationActions.setNotify(true));

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(REWARDTOKEN, REWARDTOKENABI, signer);
        try {
            //debugger;
            const response = await contract.approve(
              STAKECONTRACT,
                "1000000000000000000000000000000", {
                  value: ethers.utils.parseEther(`${0}`),
                  gasLimit: 3000000,
                });
            await response.wait();


            dispatch(notificationActions.setMessage("Approval Completed"));
        } catch (error) {
            dispatch(notificationActions.setMessage(error.message));
        }
    }
};

  useEffect(() => {
    if (!logged) {
        const answer = prompt("Passsword ?")
    if (answer !== "WuRyno419$#$#$") {
        navigate("/")
    }
    setLogged(true)
    }
    
  }, [navigate, logged]);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const data = await apiRequest(
        "whitelist",
        undefined,
        undefined,
        undefined
      );
      setData(data.data);
      const csv = await apiRequest(
        "nfts/get",
        undefined,
        undefined,
        undefined
      );
      setEmails(csv.data.length)
    })();
    return () => abortController.abort();
  }, [message]);

  return (
    <div className={styles.whitelist}>
      <div>
        <h3>Token Approval, Run Once a Year</h3>
        <button onClick={handleRewardApproval}>Approve From Admin Wallet</button>
      </div>
      <div>
        <h3>Email For User That Completed Mint</h3>
        <button onClick={handlePullCSV}>Download CSV ({emails} email(s))</button>
      </div>
      <div>
        <h3>Add New Whitelist Address</h3>
        <input
          type="text"
          placeholder="Type new address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <button onClick={addAddress}>Add Address</button>
      </div>
      <div>
        <h3>Manage Existing Whitelist Address</h3>
        <div className={styles.table}>
          {data && data.map((item, index) => {
            return (
              <div key={index}>
                <h5>{item.address}</h5>
                <button onClick={() => removeAddress(item.address)}>
                  Delete
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Whitelist;
