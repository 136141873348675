import React from "react";

import styles from "./button.module.css";
import { useNavigate } from "react-router";
const Button = ({ title, path }) => {
  const navigate = useNavigate()
  return <button onClick={path.includes("http") ? () => { window.open(path, "_blank") } : () => { navigate(path) }} className={styles.button}>{title}</button>;
};

export default Button;
